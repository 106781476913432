<div class="my-5 py-5" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <!-- <mat-card-header>
        <mat-card-title>
            <h2>Se connecter avec Facebook</h2>
        </mat-card-title>
        <mat-card-subtitle>
            <button type="button" (click)="signInWithFB()" *ngIf="!loggedIn" mat-flat-button color="primary">Sign In
                With Facebook</button>
            <button type="button" (click)="signOut()" *ngIf="loggedIn" mat-flat-button color="primary">Sign Out From
                Facebook</button>
        </mat-card-subtitle>
    </mat-card-header> -->
    <mat-card>
        <mat-card-title>Connexion</mat-card-title>
        <mat-card-content>
            <form [formGroup]="form" fxLayout="column" fxLayoutGap="15px">
                <mat-form-field>
                    <input formControlName="email" matInput type="text" placeholder="email" />
                </mat-form-field>
                <mat-form-field>
                    <input formControlName="password" matInput type="password" placeholder="mot de passe" />
                </mat-form-field>
                <p (click)="forgotPass()">Mot de passe oublié</p>
                <mat-error *ngIf="error" color="error">{{ error }}</mat-error>
                <button color="primary" (click)="submit()" mat-raised-button>Connexion</button>
            </form>
        </mat-card-content>
    </mat-card>
</div>
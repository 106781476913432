<mat-toolbar fxLayout="row wrap" fxLayoutGap="15px">
    <img src="../../assets/logo_eventMap.png" alt="logo" routerLink="/">
    <button mat-raised-button class="link" routerLink="/createEvent">Créer un évènement</button>
    <div (click)="displayList('notconnected')" class="phone fas fa-bars fa-2x" *ngIf="!jwtToken.isAuthenticated">
    </div>
    <div *ngIf="notConnected" class="select-bars" fxLayout="column">
        <a class="fas fa-times fa-2x" (click)="displayList('notconnected')"></a>
        <button mat-raised-button class="link" (click)="displayList('notconnected')"
            routerLink="/signin">Connexion</button>
        <button mat-raised-button class="link" (click)="displayList('notconnected')"
            routerLink="/signup">Inscription</button>
    </div>
    <div (click)="displayList('connected')" class="phone fas fa-bars fa-2x" *ngIf="jwtToken.isAuthenticated">
    </div>
    <div *ngIf="connected" class="select-bars" fxLayout="column">
        <a class="fas fa-times fa-2x" (click)="displayList('connected')"></a>
        <button mat-raised-button class="link" (click)="displayList('connected')" routerLink="/profile">Mon
            compte</button>
        <button mat-raised-button class="link" (click)="logout('connected')">Deconnexion</button>
    </div>
    <input placeholder="Chercher un event" [(ngModel)]="eventSearch" (keyup)="searchEvents()" type="text"
        class="form-control" id="searchEvent" [matAutocomplete]="autoEvents" />
    <mat-autocomplete #autoEvents="matAutocomplete">
        <mat-option *ngFor="let event of eventsSearched; let i = index" [value]="event.name" (click)="selectEvent(i)">
            {{ event.name }}
        </mat-option>
    </mat-autocomplete>
    <input placeholder="Chercher quelqu'un" [(ngModel)]="userSearch" (keyup)="searchUsers()" type="text"
        class="form-control" [matAutocomplete]="autoUsers" id="finduser" />
    <mat-autocomplete #autoUsers="matAutocomplete">
        <mat-option *ngFor="let user of usersSearched; let i = index" [value]="user.name" (click)="selectUser(i)">
            {{ user.name }}
        </mat-option>
    </mat-autocomplete>
    <span fxFlex="auto"></span>
    <div class="bar-right" fxLayout="row">
        <span *ngIf="!jwtToken.isAuthenticated" fxLayoutGap="15px">
            <button mat-raised-button class="link" routerLink="/signin">Connexion</button>
            <button mat-raised-button class="link" routerLink="/signup">Inscription</button>
        </span>
        <span *ngIf="jwtToken.isAuthenticated" fxLayoutGap="15px">
            <button mat-raised-button class="link" routerLink="/profile">Mon compte</button>
            <button mat-raised-button class="link" (click)="logout()">Deconnexion</button>
        </span>
    </div>
</mat-toolbar>
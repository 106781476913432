export const Dates = [
  "1 jour",
  "2 jours",
  "3 jours",
  "4 jours",
  "5 jours",
  "6 jours",
  "1 semaine",
  "2 semaines",
  "3 semaines",
  "1 mois"
]
<div class="my-5 py-5" fxLayout="row" fxLayoutAlign="center center">
  <mat-card>
      <mat-card-title>Changement de Mot de Passe</mat-card-title>
      <mat-card-content>
          <form [formGroup]="form" fxLayout="column" fxLayoutGap="15px">
              <mat-form-field>
                  <input formControlName="oldPass" matInput type="text" placeholder="ancien mot de passe" />
              </mat-form-field>
              <mat-form-field>
                  <input formControlName="newPass" matInput type="test" placeholder="nouveau mot de passe" />
              </mat-form-field>
              <mat-error *ngIf="error" color="error">{{ error }}</mat-error>
              <button color="primary" (click)="submit()" mat-raised-button>Changer mon mot de passe</button>
          </form>
      </mat-card-content>
  </mat-card>
</div>

export const Events = [
    'Concert',
    'Sport',
    'Theatre',
    'Spectacle',
    'Cirque',
    'Exposition',
    'Salon',
    'Visite',
    'Opera',
    'Cinema',
    'Divers'
]
<div class="my-5 py-5" fxLayout="row" fxLayoutAlign="center center">
    <mat-card>
        <mat-card-title>Inscription</mat-card-title>
        <mat-card-content>
            <form [formGroup]="form" fxLayout="column" fxLayoutGap="15px">
                <mat-form-field>
                    <input formControlName="email" matInput type="text" placeholder="email" />
                </mat-form-field>
                <mat-form-field>
                    <input formControlName="name" matInput type="text" placeholder="nom" />
                </mat-form-field>
                <input type="file" (change)="onPicture($event)" fxHide="true" name="picture" id="picture" />
                <label for="picture">photo (optionnel)</label>
                <mat-radio-group formControlName="profile_type" name="profile_type" fxLayoutGap="20px" required>
                    <mat-radio-button value="public">public</mat-radio-button>
                    <mat-radio-button value="privé">privé</mat-radio-button>
                </mat-radio-group>
                <mat-form-field>
                    <input formControlName="password" matInput type="password" placeholder="mot de passe" />
                </mat-form-field>
                <mat-error *ngIf="error">{{ error }}</mat-error>
                <button color="primary" (click)="submit()" mat-raised-button>Inscription</button>
            </form>
        </mat-card-content>
    </mat-card>
</div>